<template>
  <PersonRegister :person="person"></PersonRegister>
</template>
<script>
import PersonRegister from "common/components/Register/PersonRegister.vue";

export default {
  components: { PersonRegister },
  props: ["person"],
};
</script>
